.schedule-form-admin {
  
  .input-container .input-wrap {
    input::placeholder {
      color: black;
    }
    
    .icon {
      background-color: black;

      &.arrow {
        width: 10px;
      }
    }
  }
  
  .checkbox-input-container {
    margin-bottom: 16px;

    .checkbox-container {
      align-items: center;

      .checkbox-style {
        margin-left: 0;
      }
    }
    
  }

  .time-container {
    display: flex;

    .input-time-container {
      margin-right: 16px;
    }
  }
}