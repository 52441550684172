@import '../../../../assets/styles/color.scss';

.header-detail-wrap.account {
  position: relative;

  .acronym-info {
    width: 100px;
    height: 100px;
    left: 38%;
    background: $pink;
    border: 3px solid #fff9f9;
    border-radius: 100%;
    position: absolute;
    top: 15px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
  }
}
