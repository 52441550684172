.content-wrap {
  ol {
    padding: 0 20px;
    font-size: 16px;
    line-height: 22px;

    li {
      margin-bottom: 8px;
    }
  }
}