@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/color.scss';

.number-input-container {
  display: flex;
  align-items: baseline;
  position: relative;

  &.err {
    .label {
      color: red;
    }

    .input-wrap input {
      border-color: red;
    }

    .input-group {
      .prependText {
        border-color: red;
      }
    }
  }

  &.hanging-label {
    .label {
      @include hanging-label;
    }
  }

  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .input-wrap {
    width: 100%;
    margin-bottom: 16px;
    position: relative;
    margin-top: 20px;

    input {
      @extend %input-styling;
      text-align: center;
      border: 1px solid $dark-grey;
      font-size: 20px;
      border-radius: 10px;

      &:read-only {
        pointer-events: none;
      }

      /* placeholder */
      &::placeholder {
        color: white;
      }
    }

    textarea {
      width: 100%;
      // border: 1px solid $grey-border;
      border-radius: 8px;
      font-size: 16px;
      line-height: 16px;
      padding: 18px 16px;

      /* placeholder */
      &::placeholder {
        color: white;
      }

      &:read-only {
        // background: $grey;
        pointer-events: none;
      }
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }

    .input-group {
      display: flex;
      align-items: center;
    }

    .icon.icon-input-left {
      margin-right: 16px;
    }

    .icon.icon-input-right {
      position: absolute;
      right: 8px;
    }

    .detail-input {
      font-size: 12px;
      line-height: 14px;
      color: $dark-grey;
    }

    button {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      font-size: 30px;
      line-height: 0;

      &.minus {
        margin-right: 60px;
      }

      &.add {
        margin-left: 60px;
      }
    }
  }
  
  .err-message {
    font-size: 12px;
    color: red;
    margin-top: 4px;
    margin-left: 100px;
  }

  .detail-input {
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
  }
}