@import '../../assets/styles/color.scss';

.schedule-container {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 51px - 115px);

  &#history {
    min-height: calc(100vh - 141px);
  }

  .top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .btn.history {
      font-size: 14px;

      .icon {
        width: 8px;
        margin-left: 6px;
      }
    }
  }

  .schedule-list {
    width: 100%;
  }

  .empty-class {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin-top: 50px;
    line-height: 24px;
    text-align: center;

    .icon.empty {
      width: 50px;
      height: 50px;
    }

    button {
      margin-top: 30px;
    }
  }
}