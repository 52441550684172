@import '../../../assets/styles/color.scss';

.floating-btn {
  position: sticky;
  bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  
  .btn-wrap {
    background: white;
    padding: 8px;
    width: max-content;
    border-radius: 16px;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
  }
  
  button {
    border: 0;
    background: transparent;
    color: $dark-grey;
    border-left: 1px solid $dark-grey;
    padding: 0 8px;
    font-weight: bold;
    position: relative;

    &:first-child {
      border-left: 0;
    }

    &.active {
      color: $pink;
    }
  }
}

.filter-drawer-component {
  .filter-item-container {
    border-top: 1px solid $light-grey;
    padding: 16px 32px;
    display: flex;
    justify-content: space-between;

    &.checkbox {
      flex-direction: column;

      .title {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 16px;
      }

      .checkbox-input-container.filter-item {
        margin-bottom: 16px;

        .checkbox-container {
          align-items: center;
          justify-content: space-between;
          
          .checkbox-style {
            order: 1;
          }
        }
      }
    }
  }

  .btn {
    margin: 0 auto;
  }
}