@import '../../assets/styles/variables.scss';
@import '../../assets/styles/color.scss';

.register-container {
  @extend %register-styling;

  .title {
    .desc {
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      color: $dark-grey;
      margin-top: 8px;
    }
  }
  
  &#step3 {
    padding-top: 80px;

    .title {
      margin-bottom: 32px;
    }

    .btn {
      margin-top: 16px;
    }
  }
}