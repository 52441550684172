@import '../../assets/styles/color.scss';

.top-nav-container {
  width: 90%;
  min-width: 280px;
  background: $pink;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  padding: 18px 16px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -55px;
  box-sizing: border-box;
}