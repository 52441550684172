@import '../../assets/styles/color.scss';

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.schedule-class-admin {
  height: calc(100vh - 51px); 
  padding: 33px 32px 16px;
  box-sizing: border-box;
  overflow: scroll;
  
  .calendar-event {
    .label {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 8px;
      border-bottom: 1px solid black;
      margin-bottom: 16px;
    }

    .event-list {
      .event-item {
        display: flex;
        margin-bottom: 24px;
        color: white;
        font-weight: 600;
        background: $pink;
        border-radius: 8px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        .date {
          min-width: 100px;
          @extend %flex-center;
          background: $purple;
          border-radius: 8px;
          box-sizing: border-box;
        }

        .name {
          display: flex;
          align-items: center;
          padding: 8px 0 8px 16px;
          box-sizing: border-box;
        }

        .edit-btn {
          position: absolute;
          right: 0;
          bottom: -5px;
          width: 50px;
          height: 15px;
          background: #707070;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
          border-radius: 16px;
          font-size: 9px;
          cursor: pointer;
          @extend %flex-center;
        }
      }
    }
  }
}

.add-schedule {
  width: 45px;
  height: 45px;
  font-size: 30px;
  position: sticky;
  bottom: 20px;
  margin-left: 80%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.7);
}