$margin-horizontal: 20px;
$margin-vertical: 20px;
$padding-horizontal: 16px;
$padding-vertical: 14px;
$translate-y: calc(100% + 20px);
$opacity-transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
$slide-transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
$bounce-transition: transform 400ms cubic-bezier(0, 0.94, 0.47, 1.18) 0ms;
$action-btn-width: 56px;

.snackbar.v4 {
  background-color: #00aa5b;
  color: white;
  border-radius: 4px;
  box-sizing: border-box;
  left: 50%;
  padding: $padding-vertical $padding-horizontal;
  position: fixed;
  transform: translateX(-50%) translateY($translate-y);
  width: calc(100% - #{2 * $margin-horizontal});
  opacity: 0;
  z-index: 1000;

  &.mobile {
    max-width: 375px;
  }
  &.desktop {
    max-width: 480px;
    border-radius: 8px;
  }

  &.type-error {
    background-color: #f15c59;;
  }

  &.visible {
    transition: $opacity-transition, $bounce-transition;
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }

  &.out-grow.animating {
    transition: $opacity-transition, $slide-transition;
    transform: translateX(-50%) translateY(0) scale(0.8);
    opacity: 0;

    &:nth-last-child(2) {
      transform: translateX(-50%) translateY(calc(-100% - 10px)) scale(0.8);
    }

    &:nth-last-child(3) {
      transform: translateX(-50%) translateY(calc(-200% - 20px)) scale(0.8);
    }
  }

  &.out-slide.animating {
    transition: $opacity-transition, transform 225ms ease-out;
    transform: translateX(-50%) translateY($translate-y);
    opacity: 0;
  }

  .message {
    float: left;

    @at-root {
      .has-action#{&} {
        width: calc(100% - #{$action-btn-width});
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    bottom: 0px;
    box-shadow: none;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: $padding-horizontal;
    text-align: right;
    text-overflow: ellipsis;
    top: 0px;
    white-space: nowrap;
    width: $action-btn-width;

    &:active,
    &:focus {
      outline: none;
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
