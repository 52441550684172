@import '../../assets/styles/color.scss';

.btn {
  padding: 18px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: white;
  background: none;
  border: 0;
  border-radius: 32px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex; 
  align-items: center;
  justify-content: center;

  &.white-pink {
    background: white;
    color: $pink;
  }

  &.pink {
    background: $pink;
  }

  &.grey {
    background: $light-grey;
  }

  &.dark-grey {
    background: $dark-grey;
  }

  &.purple {
    background: $purple;
  }

  &.square {
    border-radius: 8px;
  }

  &.flat {
    font-size: 18px;
    padding: 0;
    background: none;
    text-transform: inherit;

    &.pink {
      color: $pink;
    }

    &.purple {
      color: $purple;
    }
  }  

  .icon {
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    background: #807d7d;
    cursor: not-allowed;
  }

}