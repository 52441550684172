@import '../../assets/styles/color.scss';

.home {
  .user-info {
    font-size: 12px;

    .name {
      margin-top: 6px;
      font-size: 24px;
      font-weight: 500;
    }
  }

  section {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .quota-wrap {
    .quota-label {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .quota-value {
      font-size: 18px;
      font-size: bold;
    }

    .btn {
      padding: 0 18px;
    }
  }

  .promo-list-section {
    margin-top: 55px;
    width: 100%;
  }

  .promo-list-container {
    display: flex;
    min-width: 280px;
    overflow-y: scroll;
    margin-bottom: 40px;

    .promo-wrap {
      // min-width: 340px;
      min-width: 100%;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  .title.special-promo {
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    margin-bottom: 8px;
  }

  .thumbs-wrapper.axis-vertical {
    display: none;
  }
}