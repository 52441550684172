@import '../../../../assets/styles/color.scss';

.search-result-container {
  margin-top: 135px;
  width: 100%;

  .info {
    height: 40vh;
    padding: 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: $dark-grey;
    font-size: 14px;
    line-height: 16px;

    .icon.empty {
      width: 60px;
      height: 60px;
      margin-bottom: 16px;
    }
  }

  .search-list {
    margin-top: 30px;

    .search-item {
      position: relative;
      margin-bottom: 24px;
      margin-left: auto;
      margin-right: auto;

      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .title {
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
        }

        .time {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          background:white;
          color: $purple;
          padding: 2px 12px;
          height: fit-content;
          border-radius: 6px;
        }
      }

      .detail {
        font-size: 14px;
        line-height: 16px;

        .row {
          margin-bottom: 4px;

          .label {
            width: 85px;
          }

          .value::before {
            content: ":";
            margin-right: 8px;
          }
        }
      }

      .choose-btn {
        position: absolute;
        right: 20px;
        bottom: 20px;

        .label-remainder-slot {
          width: max-content;
          font-size: 11px;
          position: absolute;
          top: -18px;
          right: -8px;
        }

        .sold-out-wrap {
          width: 50px;
          height: 50px;
          border: 1px solid $light-grey;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          opacity: 0.8;

          .circle {
            width: 30px;
            height: 30px;
            border: 1px solid $light-grey;
            border-radius: 100%;
            color: $light-grey;
          }

          .label {
            position: absolute;
            font-size: 12px;
            text-transform: uppercase;
            background: $purple;
            width: 100%;
            text-align: center;
            padding: 2px 4px;
            transform: rotate(-20deg);
            border: 1px solid $light-grey;
          }
        }

        .btn {
          font-size: 14px;
          border-radius: 10px;
          padding: 2px 18px;
          filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
        }
      }
    }
  }
}