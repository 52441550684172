.create-schedule-admin {
  .btn-wrap {
    display: flex;
    justify-content: space-between;

    .btn {
      border-radius: 0;
      padding: 10px;
      width: 48%;
    }
  }
}