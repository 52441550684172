@mixin snackbar-stacks($max-displayed-snackbars) {
  $y: -100%;
  $gap: 10px; // gap between snackbar

  @for $i from 2 to $max-displayed-snackbars + 1 {
    &:nth-last-child(#{$i}) {
      transform: translateX(-50%)
        translateY(calc(#{$y * ($i - 1)} - #{$gap * ($i - 1)}));
    }
  }

  &:nth-last-child(n + #{$max-displayed-snackbars + 1}) {
    display: none;
  }
}

.snackbar-wrapper.v4 {
  .snackbar.v4.visible {
    @include snackbar-stacks(3);
  }
}
