@import '../../assets/styles/color.scss';

.the-loading-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 99;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    animation: spin .6s linear infinite;
    border: 5px solid $pink;
    border-radius: 50%;
    border-top: 5px solid white;
    height: 32px;
    width: 32px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(180deg); }
    100% { transform: rotate(360deg); }
  }
}