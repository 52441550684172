body {
  margin: 0;
  font-family: 'Roboto', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stop-scrolling {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

b {
  font-weight: 500!important;
}

.layout-wrap {
  background: #FFF9F9;
  min-height: 100vh;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  filter: drop-shadow(0px 2px 8px rgba(48, 49, 53, 0.16));
}

.box {
  padding: 16px;
  background: #E5E5E5;
  border-radius: 8px;
  margin-bottom: 32px;
}