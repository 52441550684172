.change-password-container {
  width: 80%;
  min-width: 300px;
  margin: 0 auto;

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 40px;
    margin-top: 60px;
  }

  form .input-container {
    margin-bottom: 16px;
  }

  .btn {
    width: 100%;
  }

  .number-verification-container {
    margin-bottom: 24px;
    
    .input-container {
      margin-bottom: 0;
    }
  }
}