@import '../../../../assets/styles/color.scss';

.number-verification-container {
  &> .label {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $dark-grey;
    margin-bottom: 8px;
  }

  .otp-input {
    display: flex;
    justify-content: space-between;
    
    .input-container {
      width: 20%;

      input {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
      }
    }

    &.err {
      input {
        color: red;
      }
    }
  }

  .err-message {
    color: red;
    text-align: center;
    font-size: 14px;
  }

  .timer-wrap {
    text-align: center;
    margin-top: 16px;
    color: $dark-grey;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;

    .btn {
      width: max-content;
      font-weight: 900;
      font-size: 12px;
      line-height: 11px;
      margin-left: 4px;
      margin-top: 0;
    }
  }
}