.drawer-component {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background-color: rgba(20, 22, 25, 0.3);
  overflow: hidden;

  .drawer-overlay {
    position: absolute;
    -webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: inherit;
    height: 100%;
    z-index: 101;
    background-color: transparent;
  }

  .drawer-header {
    padding: 0 16px 0 20px;
    -webkit-overflow-scrolling: touch;

    .drawer-header-title-container {
      position: relative;
      padding-bottom: 16px;

      .drawer-header-logo {
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;

        i {
          display: block;
          line-height: 24px;
          font-size: 20px;
          color: #58627a;
        }
      }

      .drawer-header-title {
        display: inline-block;
        padding-left: 40px;
        color: #35405a;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .drawer-header-subtitle-container {
      padding: 8px 0 20px 0;

      .drawer-header-subtitle {
        display: block;
        font-size: 14px;
        line-height: 21px;
        color: #58627a;
      }
    }
  }

  .drawer-body {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .drawer-container {
    -webkit-overflow-scrolling: touch;
    position: absolute;
    bottom: 0;
    z-index: 102;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-shadow: 0 5px 15px 0 rgba(229, 233, 243, 0.72);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .drawer-ellipsis-container {
      -webkit-overflow-scrolling: touch;
      display: block;
      width: 100%;
      padding: 8px 0 16px 0;

      .drawer-ellipsis {
        display: block;
        margin: 0 auto;
        height: 4px;
        width: 40px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        background-color: #dee2ee;
      }
    }
  }
}
