@import '../../assets/styles/color.scss';

.member {
  height: 100vh; 
  padding: 16px 32px;

  .member-list-container {
    margin-top: 24px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .download-wrap {
        display: flex;
        align-items: center;
      }

      .label {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
      }
  
      .filter-wrap {
        font-size: 14px;
        line-height: 16px;
        display: flex;
        
        button:last-child{
          margin-left: 8px;
          border-left: 1px solid $purple;
          border-radius: 0;
          padding-left: 8px;
        }
      }
    }

    .member-list {
      height: calc(100vh - 200px);
      overflow: scroll;
      padding-top: 10px;

      .member-wrap {
        display: flex;
        margin-bottom: 35px;
        cursor: pointer;

        .left {
          display: flex;
          width: 100%;
          align-items: center;
          position: relative;

          .quota-wrap {
            min-width: 60px;
            height: 60px;
            background: $purple;
            border: 4px solid #FFF9F9;  
            border-radius: 100%;
            color: white;
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: absolute;
            z-index: 1;
          }

          .detail-wrap {
            width: 100%;
            height: 40px;
            background: $purple;
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            padding-left: calc(60px + 8px);
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: white;
            position: relative;

            .phone {
              width: 60%;
              min-width: 157px;
              padding: 4px 0;
              background: $pink;
              box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
              border-radius: 4px;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              position: absolute;
              bottom: -10px;
              right: 10%;
            }

            .name {
              padding-top: 6px;
            }
          }
        }

        .top-up.btn {
          width: 42px;
          height: 42px;
          background: $purple;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          margin-left: 14px;
          font-size: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .empty-member {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    .icon.empty {
      width: 50px;
      height: 50px;
    }
  }
}

footer.member-footer {
  background: transparent;
  z-index: 99;

  button {
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .add-icon {
    font-size: 20px;
    padding-right: 8px;
  }
}