@import '../../../../assets/styles/color.scss';

.promo-wrap {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 14px;
      margin-bottom: 8px;
    }

    .desc {
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
      color: $dark-grey;
    }

    .btn {
      font-size: 12px;
    }
  }

  img {
    display: inline-block;
    width: 100%;
    min-height: 175px;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
    border-radius: 8px;
    object-fit: cover;
  }
}