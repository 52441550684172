.class-admin {
  height: calc(100vh - 51px - 70px); 
  padding: 33px 32px 16px;
  box-sizing: border-box;

  .class-list-container {
    margin-top: 28px;

    .label {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }

    .class-list {
      margin-top: 16px;
      max-height: calc(100vh - 51px - 75px - 70px - 100px);
      overflow: scroll;

      .class-wrap {
        height: 95px;
        background: #EAEAEA;
        border: 1px solid #D77FA1;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #707070;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}