@import '../../assets/styles/variables.scss';

.login-container {
  @extend %new-user-container-styling;
  @include centeredFlex;
  flex-direction: column;
  
  .btn {
    width: 100%;

    &.flat {
      font-size: 14px;
      margin-top: 14px;
      text-align: right;
    }
  }

  form {
    width: 100%;

    .input-container {
      margin-bottom: 16px;
    }
  }
}

#login-need-approve .modal-popup {
  width: 90%;
}