.class-form-admin {
  padding-top: 40px;
  margin-bottom: 0;

  .input-container {
    margin-bottom: 24px;

    .input-wrap .icon.icon-input-right {
      background-color: black;
      width: 10px;
    }
  }
}