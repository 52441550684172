@import '../../assets/styles/color.scss';

.home {
  .menu-container {
    padding: 32px 20px;
    box-sizing: border-box;

    .menu-wrap {
      display: flex;
      width: 100%;
      height: 124px;
      box-sizing: border-box;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;

      .left {
        min-width: 127px;
        background: $pink;
        display: flex;
        justify-content: center;
        align-items: center;

        .illustration-menu {
          width: 75px;
          height: 75px;
          background: white;

          &.class {
            background: url('../../assets/img/adminHome/calendar.svg');
          }

          &.instructor {
            background: url('../../assets/img/adminHome/instructor.svg')no-repeat;
            background-size: contain;
          }

          &.time {
            background: url('../../assets/img/adminHome/time.svg');
          }

          &.member {
            background: url('../../assets/img/adminHome/people.svg')no-repeat;
            background-position: center;
          }
        }
      }

      .right {
        width: 100%;
        background: #EAEAEA;
        display: flex;
        align-items: center;
        padding: 0 20px;
      }
    }
  }
}