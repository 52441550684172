@import '../../../assets/styles/color.scss';

.checkbox-input-container {

  &.err {
    .checkbox-container .checkbox-style {
      border-color: red;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .checkbox-container {
    display: flex;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
  
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
    .checkbox-style {
      min-width: 18px;
      height: 18px;
      background-color: white;
      border: 1px solid #ddd;
      border-radius: 3px;
      margin: 4px;
      margin-right: 8px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3px;
  
      &:after {
        content: "";
        background-color: transparent;
        position: absolute;
        top: 0;
        width: 6px;
        height: 12px;
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        transform: rotate(45deg);
          -o-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
      }
  
    }
  
    input:checked ~ .checkbox-style{
      background-color: $light-blue;
      border: 1px solid $light-blue;
  
      &:after {
        display: block;
      }
    }
  }

  .err-message {
    font-size: 12px;
    color: red;
    margin-top: 4px;
    margin-left: calc(18px + 8px);
  }
}
