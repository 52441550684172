@import '../../../assets/styles/color.scss';

.edit-member {
  .member-form {
    padding: 60px 32px;
    height: calc(200vh - 500px); 
  }

  .input-container {
    margin-bottom: 32px;
  }

  .number-input-container .input-wrap{
    width: 80%;
    margin: 16px auto;
  }

  .history-btn {
    margin: 20px 32px;
    font-size: 14px;
    position: absolute;
    right: 0;
  }
  
  .submit-form {
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    position: sticky;
    bottom: 0;
    height: 70px;
    background: $pink;
    display: flex;
    justify-content: space-around;
    color: white;
  }

  .approval-wrap {
    .label-top {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 16px;
    }
  }
  
  .btn#delete-member {
    width: 100%;
    margin-top: 20px;
  }
}