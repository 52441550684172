@import '../../assets/styles/color.scss';

.header-wrap {
  position: relative;
  text-align: center;
  padding: 16px;
  font-size: 16px;
  background: $purple;
  color: white;
  min-height: 51px;
  box-sizing: border-box;

  .icon{
    position: absolute;
    left: 16px;
    background-color: white;

    &.hamburger {
      left: initial;
      right: 16px;
    }
  }
}