.search-wrap {
  flex-direction: column;
  padding: 18px 24px;

  .input-container {
    .input-wrap {
      margin-bottom: 8px;
      
      input {
        color: white;
        border-bottom-color: white;
        padding: 8px 0;
        height: auto;
        font-size: 14px;
      }
      
      .icon.icon-input-right {
        width: 11px;
      }
    }

    &.err {
      .icon {
        background-color: red;
      }
      
      .input-wrap input {
        border-bottom-color: red;

        &::placeholder {
          color: red;
        }
      }

      .err-message {
        margin-left: 30px;
      }
    }
  }
  
  .radio-input-container {
    margin: 8px auto;
    
    .radio-container {
      margin-right: 12px;
    }
  }

  .btn {
    font-size: 14px;
    border-radius: 10px;
    padding: 4px 8px;
    margin-top: 8px;
  }
}