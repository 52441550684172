@import '../../../assets/styles/color.scss';

.contact-us-container {

  main {
    padding: 0 32px;
    position: relative;
  }

  .contact-list {
    margin-top: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid black;
    color: $dark-grey;

    .item {
      display: flex;
      margin-bottom: 10px;
      
      .icon {
        margin-right: 8px;
      }
    }
  }

  .location-wrap {
    margin-top: 50px;

    .top {
      margin-bottom: 20px;

      .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }
    }

    .address-wrap {
      display: flex;
      margin-bottom: 16px;
      font-size: 12px;

      .icon {
        margin-right: 16px;
        min-width: 20px;
      }
    }

    .address-map {
      min-width: 276px;
      width: 90%;
      display: block;
      height: 166px;
      background: grey;
      border-radius: 8px;
      margin: 0 auto 40px;
      object-fit: cover;
      
    }
  }
}