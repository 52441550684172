@import '../../../assets/styles/color.scss';

.header-detail-wrap {
  padding: 16px 24px 70px;
  background: $purple;
  color: white;
  position: relative;

  .title {
    margin-top: 6px;
    font-size: 24px;
    font-weight: 500;
  }

  .desc {
    font-size: 12px;
  }

  .ornament {
    width: 100%;
    height: 20px;
    background: #FFF9F9;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.simple {
    padding-bottom: 50px;
  }
}