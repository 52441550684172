@import '../../assets/styles/color.scss';

.card {
  width: 90%;
  min-width: 280px;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 45px;
  color: white;
  box-sizing: border-box;

  &.pink {
    background: $pink;
  }

  &.purple {
    background: $purple;
  }

  &.grey {
    background: $dark-grey;
  }
}