@import '../../../../assets/styles/color.scss';

.schedule-card-container {
  position: relative;
  margin-bottom: 20px;

  .status {
    background: $pink;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    padding: 2px 15px;
    position: absolute;
    border-radius: 6px;
    bottom: -6px;
    right: 16px;

    &.done {
      background: $purple;
    }
  }
}

.card.schedule-card {
  width: 99%;
  margin-bottom: 0;

  .summary-wrap {
    display: flex;
    align-items: center;
    position: relative;
    width: 90%;

    .time {
      padding: 8px 10px;
      background: white;
      color: $purple;
      border-radius: 8px;
      height: fit-content;
      font-weight: 500;
      margin-right: 16px;
    }

    .detail-class {
      width: 100%;
      
      .name {
        font-weight: 500;
        border-bottom: 1px solid white;
        padding-bottom: 4px;
        margin-bottom: 4px;
      }
    }
  }

  .detail-wrap {
    .title-date {
      margin-bottom: 16px;

      .title {
        font-weight: 500;
        font-size: 18px;
        border-bottom: 1px solid black;
        padding-bottom: 4px;
        margin-bottom: 4px;
      }
    }

    .cancel-info {
      font-size: 14px;
      margin-top: 8px;
      
      b {
        font-weight: bold;
      }
    }

    .btn-wrap {
      margin-top: 24px;

      .btn {
        width: 100%;
        padding: 8px 16px;
        margin-bottom: 10px;
      }
    }
  }
}