@import '../../assets/styles//variables.scss';

.new-user-container {
  @include centeredFlex;
  flex-direction: column;
  padding: 36px;
  height: 100vh;
  box-sizing: border-box;

  .btn {
    width: 100%;
  }

  .or-wrap {
    @include centeredFlex;
    width: 95%;
    position: relative;
    padding: 14px 0;

    .line-word {
      width: 100%;
      height: 1px;
      border-top:1px solid #707070;
      position: absolute;
      z-index: -1;
    }

    .word {
      font-size: 12px;
      text-align: center;
      color: #707070;
      padding: 0 14px;
      background: white;
    }
  }
}