@import '../../../assets/styles/color.scss';

.card-collapse {
  border: 2px solid transparent;
  padding: 0;

  &.open {
    border-color: $pink;
  }

  .summary {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .detail {
    padding: 20px;
    background: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: $dark-grey;
  }
}