@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/color.scss';

.input-container {
  display: flex;
  align-items: baseline;
  position: relative;

  &.err {
    .label {
      color: red;
    }

    .input-wrap input {
      border-color: red;
    }

    .input-group {
      .prependText {
        border-color: red;
      }
    }
  }

  &.hanging-label {
    .label {
      @include hanging-label;
    }
  }

  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.text {
    .label {
      &:not(.active) {
        margin-left: 10px;
      }

      &.active {
        top: -10px;
      }
    }
  }

  &.with-placeholder .input-wrap{
    input {
      &::placeholder {
        color: grey;
      }
    }
  }

  .input-wrap {
    width: 100%;
    margin-bottom: 16px;
    position: relative;

    input {
      @extend %input-styling;
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;

      &:read-only:not([type=button]) {
        pointer-events: none;
      }

      /* placeholder */
      &::placeholder {
        color: white;
      }
    }
    
    input[type=button] {
      text-align: left;
    }

    textarea {
      width: 100%;
      // border: 1px solid $grey-border;
      border-radius: 8px;
      font-size: 16px;
      line-height: 16px;
      padding: 18px 16px;
      border: 0;
      font-family: inherit;

      /* placeholder */
      &::placeholder {
        color: white;
      }

      &:read-only {
        // background: $grey;
        pointer-events: none;
      }
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }

    .input-group {
      display: flex;
      align-items: center;
    }

    .icon.icon-input-left {
      margin-right: 16px;
    }

    .icon.icon-input-right {
      position: absolute;
      right: 8px;
    }

    .detail-input {
      font-size: 12px;
      line-height: 14px;
      color: $dark-grey;
    }
  }
  
  .err-message {
    font-size: 12px;
    color: red;
    margin-top: 4px;
  }

  .detail-input {
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
  }

  .text.left {
    padding-right: 8px;
  }
}