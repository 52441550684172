@import '../../assets/styles/color.scss';

$arrow: '../../assets/icon/arrow.svg';
$class: '../../assets/icon/class.svg';
$calendar: '../../assets/img/footer/regis-class.svg';
$close: '../../assets/icon/close.svg';
$check-mark: '../../assets/icon/check-mark.svg';
$empty: '../../assets/icon/empty.svg';
$search: '../../assets/icon/search.svg';
$account: '../../assets/icon/account.svg';
$password: '../../assets/icon/password.svg';
$document: '../../assets/icon/document.svg';
$payment: '../../assets/icon/payment.svg';
$shop: '../../assets/icon/shop.svg';
$help: '../../assets/img/footer/help.svg';

$phone: '../../assets/icon/phone.svg';
$wa: '../../assets/icon/wa.svg';
$email: '../../assets/icon/email.svg';
$fb: '../../assets/icon/fb.svg';
$ig: '../../assets/icon/ig.svg';
$address-search: '../../assets/icon/address-search.svg';
$hamburger: '../../assets/icon/hamburger.svg';
$user: '../../assets/icon/user.svg';
$people: '../../assets/icon/people-fill.svg';
$download: '../../assets/icon/cloud-download-fill.svg';

@mixin icon($width, $height, $img, $bg-color) {
  width: $width;
  height: $height;
  background-color: $bg-color;
  -webkit-mask: url($img) no-repeat center;
  mask: url($img) no-repeat center;
}

.icon {
  width: 18px;
  height: 20px;
  display: inline-block;

  &.arrow {
    @include icon(18px, 20px, $arrow, $pink);
    mask-size: contain;

    &.left {
      transform: rotate(180deg);
    }

    &.down {
      transform: rotate(90deg);
    }

    &.white {
      background-color: white;
    }

    &.purple {
      background-color: $purple;
    }

    &.dark-grey {
      background-color: $dark-grey;
    }
  }

  &.close {
    @include icon(18px, 20px, $close, $dark-grey);
    mask-size: contain;
  }

  &.close {
    @include icon(18px, 20px, $close, $dark-grey);
    mask-size: contain;
  }

  &.check {
    @include icon(18px, 20px, $check-mark, $purple);
    mask-size: contain;
  }

  &.class {
    @include icon(18px, 20px, $class, white);
    mask-size: contain;
  }

  &.user {
    @include icon(18px, 20px, $user, white);
    mask-size: contain;
  }

  &.people {
    @include icon(18px, 20px, $people, white);
    mask-size: contain;
  }

  &.calendar {
    @include icon(18px, 20px, $calendar, white);
    mask-size: contain;
  }

  &.search {
    @include icon(18px, 20px, $search, $pink);
    mask-size: contain;
  }

  &.empty {
    @include icon(18px, 20px, $empty, $pink);
    mask-size: contain;
  }

  &.account {
    @include icon(18px, 20px, $account, $pink);
    mask-size: contain;
  }

  &.password {
    @include icon(18px, 20px, $password, $pink);
    mask-size: contain;
  }

  &.document {
    @include icon(18px, 20px, $document, $pink);
    mask-size: contain;
  }

  &.payment {
    @include icon(18px, 20px, $document, white);
    mask-size: contain;
  }

  &.shop {
    @include icon(18px, 20px, $shop, white);
    mask-size: contain;
  }

  &.help {
    @include icon(18px, 20px, $help, white);
    mask-size: contain;
  }

  &.phone {
    @include icon(18px, 20px, $phone, $pink);
    mask-size: contain;
  }

  &.wa {
    @include icon(18px, 20px, $wa, $pink);
    mask-size: contain;
  }

  &.email {
    @include icon(18px, 20px, $email, $pink);
    mask-size: contain;
  }

  &.fb {
    @include icon(18px, 20px, $fb, $pink);
    mask-size: contain;
  }

  &.ig {
    @include icon(18px, 20px, $ig, $pink);
    mask-size: contain;
  }

  &.address-search {
    @include icon(18px, 20px, $address-search, $pink);
    mask-size: contain;
  }

  &.white {
    background-color: white;
  }

  &.hamburger {
    @include icon(23px, 16px, $hamburger, white);
    mask-size: contain;
  }

  &.download {
    @include icon(23px, 16px, $download, black);
    mask-size: contain;

    &.purple {
      background-color: $purple;
    }
  }
}