@import '../../assets/styles/color.scss';

.profile-account-container {
  margin-top: 55px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .user-phone-detail {
    width: 85%;
    min-width: 312px;
    background: $pink;
    border-radius: 10px;
    color: white;
    padding: 18px;
    text-align: center;
    margin-bottom: 16px;

    .name {
      font-size: 18px;
      line-height: 21px;
      font-weight: bold;
    }

    .phone {
      font-size: 14px;
      line-height: 16px;
      border-top: 1px solid white;
      margin-top: 8px;
      padding-top: 8px;
    }
  }

  .user-menu {
    margin-top: 8px;
    width: 95%;

    .input-container .input-wrap input::placeholder {
      color: $dark-grey;
    }
  }

  .btn#logout {
    width: 95%;
    border-radius: 10px;
    margin-top: 16px;
  }
}