.participant-container {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
    font-weight: 500;
  }

  .member-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d2d2d2;

    .number {
      padding-right: 4px;
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .icon.close {
      width: 14px;
      background-color: red;
    }
  }

  .icon.download.disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }

  .add-member-btn {
    text-align: center;
    color: #A68DAD;
    font-weight: bold;
    padding:8px;
  }
}

.delete-schedule {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}