@import '../../assets/styles/color.scss';

footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  background: $purple;
  display: flex;
  justify-content: space-around;
  color: white;

  .footer-menu {
    font-size: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .nav-icon {
      width: 30px;
      height: 34px;
      display: inline-block;
      margin-bottom: 7px;

      &#class_regis {
        background: url('../../assets/img/footer/regis-class.svg') no-repeat;
      }

      &#my_schedule {
        background: url('../../assets/img/footer/my-schedule.svg') no-repeat;
      }

      &#account {
        background: url('../../assets/img/footer/account.svg') no-repeat;
      }

      &#help {
        background: url('../../assets/img/footer/help.svg') no-repeat;
      }
    }
  }
}