// mixins ===============================================
@mixin centeredFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin alignCenterFlex {
  display: flex;
  flex-flow: row;
  align-items: center;
}

@mixin hanging-label {
  padding-top: 6px;
  // color: $text-mute;
  position: absolute;
  top: 0;
  left: 0;
  // height: 100%;
  font-size: 14px;
  font-weight: 300;
  cursor: text;
  transition: transform .2s ease-out;
  transform-origin: 0% 100%;
  text-align: initial;
  transform: translateY(6px);
  pointer-events: none;
  z-index: 1;
  
  &.active {
    transform: translateY(-12px) scale(1);
    transform-origin: 0 6px;
    z-index: 1;
    left: 0;
    top: -5px;
    height: 20px;
    padding: 0;

    // &.primary {
    //   // color: $primary;
    // }
  }

  &.danger {
    color: red;
  }
}

%input-styling {
  width: 100%;
  border: 0;
  border-bottom: 1px solid grey;
  font-size: 16px;
  line-height: 16px;
  height: 39px;
  padding: 10px 0 10px;
  box-sizing: border-box;
  background: transparent;

  /* placeholder */
  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  
  &:focus {
    outline: none;
}
}

%new-user-container-styling {
  padding: 36px 56px;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    margin: 40px;
  }
}

%register-styling {
  @extend %new-user-container-styling;
  padding-left: 45px;
  padding-top: 135px;

  .title {
    margin-bottom: 150px;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  .btn {
    margin-top: 52px;
    width: 100%;
  }
}