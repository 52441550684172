@import '../../../../assets/styles/color.scss';

#top-up-modal {
  .modal-popup {
    width: 90%;
  }

  .top-up-modal-container {
    .member-detail {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 4px;
  
      .phone {
        color: $dark-grey;
      }
    }
  
    .row {
      font-size: 18px;
      display: flex;
      justify-content: space-between;

      .label {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
      }
      
      &.remaining {
        margin-top: 10px;
      }
  
      &.final {
        margin-top: 8px;
        text-align: center;
      }
    }
  
    .top-up-input-wrap {
      border-top: 1px solid $dark-grey;
      border-bottom: 1px solid $dark-grey;
      padding: 5px 0;
      margin: 10px 0;
  
      .number-input-container {
        width: 80%;
        margin: 32px auto 0;

        .input-wrap {
          margin-top: 20px;
        }

        .label {
          width: 100%;
          text-align: center;
          font-weight: 700;
          font-size: 18px;
        }

        button {
          &.minus {
            margin-right: 20px;
          }

          &.add {
            margin-left: 20px;
          }
        }
      }
    }
    
  }
}