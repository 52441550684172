@import '../../assets/styles/color.scss';

.welcome-splash-wrap {
  position: relative;

  .frame-animation { 
    position: absolute;
    top: 0%;
    width: 100%;
    text-align: center;
    opacity: 0;
    animation: blurFadeInOut 1.5s ease-in backwards;
    
    &#frame1 {
      animation-delay: 0s;
    }

    &#frame2{
      animation: blurFadeIn 1s ease-out 1s backwards;
      color: transparent;
      opacity: 1;
    }
  }
}

@keyframes blurFadeInOut{
  0%{
    opacity: 0;
    transform: scale(0.9);
  }
  20%,75%{
    opacity: 1;
    transform: scale(1);
  }
  100%{
    opacity: 0;;
    transform: scale(0);
  }
}

@keyframes blurFadeIn{
  0%{
    opacity: 0;
    transform: scale(0);
    background: transparent;
  }
  50%{
    opacity: 0.5;
    transform: scale(0.5);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}