@import '../../../assets/styles/color.scss';
@import '../stylesHelpContent.scss';

.booking-help-container {
  main {
    padding: 0 32px;
    position: relative;
  }

  .body-wrap {
    margin-top: 50px;

    .top {
      margin-bottom: 20px;

      .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }
    }

    .content-wrap {
      display: flex;
      margin-bottom: 16px;
      font-size: 12px;
      word-wrap: break-word;

      .icon {
        margin-right: 16px;
        min-width: 20px;
      }
    }
  }
}
