@import '../../../../assets/styles/color.scss';

.top.header-help{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  
  .search-bar {
    background: white;
    border: 1px solid $light-grey;
    border-radius: 16px;
    width: 90%;
    margin: 0 auto 0;
    position: absolute;
    top: -36px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;

    .icon {
      margin-left: 8px;
      position: absolute;
      right: 16px;
      top: 6px;
    }

    input {
      width: inherit;
      border: 0;
      border-radius: 10px;
      padding: 8px 16px;

      &::placeholder {
        font-size: 11px;
      }
    }
  }
}