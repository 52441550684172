@import '../../../assets/styles/color.scss';

.radio-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &.err {
    .radio-container {
      .label {
        margin-bottom: 22px;
      }

      .radio-style {
        border-color: red;
      }
    }
  }

  &.long-questions {
    position: relative;

    .label {
      width: 700px;
    }

    .radio-input-wrap {
      position: absolute;
      right: 0;
    }

    .radio-container {
      margin-right: 0;
      &:not(:first-child) {
        margin-left: 60px;
      }
    }
  }

  .label {
    width: 175px;
  }

  .radio-options-container {
    display: flex;
  }
  
  .radio-options-wrap {
    display: flex;
    align-items: center;
  }

  .radio-container {
    position: relative;
    padding-left: 30px;
    margin-right: 60px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 24px;
    display: flex;
    align-items: center;
  
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .option-label {
      color: white;
      font-weight: 500;
    }
  
    &.disabled{
      color: #c6cbda;
    }
  
    .radio-style {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px; 
      background-color: white;
      border-radius: 50%;
      border: 2px solid transparent;
    
      &:after {    
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $purple;
        content: "";
        position: absolute;
        display: none;
      }
    
      &.disabled{
        border: 1px solid #dee2ee;
        background-color: #ffffff;
    
        &:after{
          background-color: #f7f7f7;
        }
      }
    
    }
    
    input:checked ~ {
      .radio-style{
        border: 2px solid $purple;
    
        &:after {
          display: block;
        }
    
        &.disabled{
          border: 2px solid #f7f7f7;
          background-color:transparent;
        }  
      }

      .option-label {
        color: white;
      }
    }
  
  }

  .err-message {
    font-size: 12px;
    color: red;
    margin-top: 4px;
    position: absolute;
  }

  .detail-input {
    font-size: 16px;
    line-height: 19px;
    color: $dark-grey;
    margin-top: 10px;
  }

  &.dark-mode {
    .radio-container {
      .radio-style {
        border: 1px solid grey;
      }
    }
  }

  &.form {
    font-size: 14px;
    font-weight: 300;
    display: block;
    margin-bottom: 20px;

    .label {
      margin-bottom:16px;
    }

    .option-label {
      color: black;
      font-weight: inherit;
    }
    
    input:checked ~ {
      .option-label {
        color: black;
        font-weight: 400;
      }
    }
  }
}