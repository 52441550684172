$modal-easing-linear: cubic-bezier(0.17, 0.67, 0.69, 0.97);
$modal-popup-radius: 12px;
$modal-popup-expand-top-space: 38px;
$modal-small-size: 100%;
$modal-medium-size: 800px;
$modal-large-size: 1000px;

.modal {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
  animation: bg-fade-in 0.3s linear both;

  @at-root {
    .closing#{&} {
      animation: bg-fade-out 0.3s linear both;
    }
  }

  .modal-wrapper {
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 1000;
    transform: translateY(100%);
    animation: modal-animation-show-bounce-y 700ms linear both;
    display: flex;
    justify-content: center;
    align-items: center;

    @at-root {
      .closing#{&} {
        animation: animation-hide-y 1s linear both;
      }
    }

    .modal-close-handle {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
    }

    .modal-mask-bounce-bottom {
      position: absolute;
      background-color: rgba(0,0,0,0.3);
      // top: 100%;
      left: 0px;
      right: 0px;
      height: 100vh;
    }

    .modal-popup {
      position: absolute;
      background-color: white;
      box-sizing: border-box;
      overflow-y: auto;
      overscroll-behavior-y: none;
      overflow-x: hidden;
      clip-path: inset(0 round $modal-popup-radius $modal-popup-radius 0 0);
      max-height: calc(100% - #{$modal-popup-expand-top-space});
      border-radius: $modal-popup-radius;

      &::-webkit-scrollbar {
        display: none;
      }

      .modal-content {
        &.small {
          width: $modal-small-size;
          min-width: 300px;

          .modal-footer {
            .btn {
              &:nth-child(1):nth-last-child(1) {
                width: calc(100%);
              }

              &:nth-child(1):nth-last-child(2),
              &:nth-child(2):nth-last-child(1) {
                width: calc(50%);
              }
            }
          }
        }

        &.medium {
          width: $modal-medium-size;
        }

        &.large {
          width: $modal-large-size;
        }

        &.bottom-border .modal-body {
          border-bottom: 1px solid grey;
        }

        &.top-border .modal-body {
          border-top: 1px solid grey;
        }
      }

      .modal-title {
        margin: 48px 32px 12px;
        font-size: 20px;
        line-height: 34px;
      }

      .modal-body {
        max-height: 314px;
        padding: 0 32px;
        overflow: scroll;
        max-width: 100%;

        p {
          margin: 0;
          max-width: 100%;
        }
      }

      .modal-footer {
        margin: 24px 32px;
        display: flex;
        justify-content: flex-end;

        .btn {
          width: 100%;
          padding: 12px;

          &:not(:last-child) {
            margin-right: 12px;
          }
        }
      }
    }

    .modal-icon-close-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
      padding: 0;
      border: 0;
      background: transparent;
    }
  }
}

@keyframes modal-animation-show-bounce-y {
  0% {
    transform: translateY(100%);
  }

  5.71% {
    transform: translateY(62.4862%);
  }

  11.31% {
    transform: translateY(36.4581%);
  }

  17.02% {
    transform: translateY(19.0777%);
  }

  22.62% {
    transform: translateY(8.665%);
  }

  28.33% {
    transform: translateY(2.7139%);
  }

  33.93% {
    transform: translateY(-0.2147%);
  }

  45.15% {
    transform: translateY(-1.7093%);
  }

  72.57% {
    transform: translateY(-0.3869%);
  }

  100% {
    transform: translateY(0%);
  }
}
