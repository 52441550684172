.datepicker-drawer-container {
  .icon {
    margin: 0;
  }
  
  .react-datepicker {
    width: 100%;
    font-size: 16px;
    border: 0;

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__header {
      background: transparent;
    }

    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
      font-size: 20px;
      margin-bottom: 8px;
      margin-top: 8px;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      top: 20px;
      height: 32px;
      width: 32px;
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    
    .react-datepicker__navigation--previous {
      left: 24px;
    }

    .react-datepicker__navigation--next {
      right: 24px;
    }

    .react-datepicker__navigation-icon::before {
      height: 15px;
      width: 17px;
    }

    .react-datepicker__day-name, 
    .react-datepicker__day, .react-datepicker__time-name {
      width: 2.5rem;
      line-height: 2.7rem;
    }
  }
}