@import '../../assets/styles/color.scss';
@import './animation.scss';

.welcome-splash-wrap {
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }

  .slide-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    height: calc(320px + 120px);
    margin-bottom: 13px;
  }

  .slide-dot-wrap {
    display: flex;
    justify-content: center;
  }

  .btn {
    position: absolute;
    right: 0;

    &#skip {
      top: 0;
      margin: 40px 40px 0 0;
      z-index: 10;
    }

    &#next {
      bottom: 0;
      margin: 0 40px 40px 0;
    }
  }

  .version {
    color: $pink;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }

  .frame-animation#frame2 section {
    position: relative;
  }

  .slide-nav {
    width: 100%;

    &>div {
      width: 100px;
      height: calc(320px + 120px);
      position: absolute;
      top: 25%;

      &.prev {
        left: 0px;
      }

      &.next {
        right: 0px;
      }
    }
  }
}