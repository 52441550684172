@import '../../../../assets/styles/color.scss';

.box-splash-wrap {
  opacity: 0;
  position: absolute;
  transition: opacity ease-in-out 0.4s;
  width: 80%;

  &.active {
    opacity: 1;
  }

  .label {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: $pink;
    margin-bottom: 22px;
    text-align: center;
  }

  .desc {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $purple;
  }
}