@import '../../assets/styles/color.scss';

.help-container {
  .top {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .search-bar {
    background: white;
    border: 1px solid $light-grey;
    border-radius: 16px;
    width: 90%;
    margin: 0 auto 0;
    position: absolute;
    top: -36px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;

    .icon {
      margin-left: 8px;
      position: absolute;
      right: 16px;
      top: 6px;
    }

    input {
      width: inherit;
      border: 0;
      border-radius: 10px;
      padding: 8px 16px;

      &::placeholder {
        font-size: 11px;
      }
    }
  }

  main {
    margin-top: 30px;
    padding: 0 32px;

    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 30px;
    }

    .category-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .menu {
        margin-bottom: 20px;
        text-align: center;
        color: $dark-grey;
        font-weight: 500;
        flex: 0 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .icon-item {
        width: 60px;
        height: 60px;
        background: $purple;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 10px;
        cursor: pointer;

        .icon {
          width: 100%;
          height: 100%;
        }
      }

      span.label {
        min-width: 60px;
        display: inline-block;
      }
    }
  }
}