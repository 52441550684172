@import '../../assets/styles/color.scss';

.class-detail-container {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .class-summary {
    flex-direction: column;
    position: relative;

    .title {
      font-size: 24px;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid white;
      display: flex;
      align-items: center;
      justify-content: center;

      .tooltip-desc {
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;
        
        .icon {
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
          border: 1px solid white;
          color: white;
          font-size: 10px;
        }
      }
    }

    .detail {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      padding-top: 8px;
      
      span {
        margin-bottom: 8px;
      }
    }

    .desc {
      text-align: justify;
    }
    
    // .instructor-name {
    //   margin-top: 8px;
    //   font-size: 14px;
    //   text-align: right;
    //   font-weight: normal;
    // }
  }

  .class-tnc {
    width: auto;
    margin-bottom: 180px;
    margin-top: -20px;
    
    .title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid white;
    }

    .tnc-list {
      padding-left: 32px;
      max-height: 400px;
      overflow: scroll;
      margin-bottom: 8px;

      li {
        margin-bottom: 8px;
      }
    }

    .checkbox-input-container {
      margin-top: 8px;
      font-size: 14px;

      .checkbox-container {
        display: flex;
        align-items: center;
      }
    }
  }

  .footer-purchasing {
    width: 100%;
    background: $pink;
    position: absolute;
    bottom: 0;
    color: white;
    padding: 16px 32px;
    box-sizing: border-box;

    .row {
      display: flex;
      justify-content: space-between;
      font-weight: 500;

      &.my-coupon .value{
        width: max-content;
        display: inherit;

        button {
          width: max-content;
          padding: 0 8px;
          margin-right: 8px;
          height: fit-content;
        }
      }
    }

    .last-row {
      border-top: 1px solid white;
      padding-top: 8px;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .btn {
      width: 100%;
      border-radius: 8px;
      padding: 8px 32px;
    }
  }
}

#class-confirm {
  .title-confirm {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}