@import '../../../assets/styles/color.scss';

.list-drawer {
  padding-bottom: 50px;
  
  .list-item {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    border-top: 1px solid $light-grey;
    color: $dark-grey;

    &:last-child {
      border-bottom: 0;
    }
  }
}