@import '../../assets/styles/color.scss';

.calendar-class {
  position: relative;

  .react-datepicker {
    width: 100%;
    font-size: 16px;
    border: 0;
    background: transparent;

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__header {
      background: transparent;
    }
    
    .react-datepicker__day-name, 
    .react-datepicker__day, 
    .react-datepicker__time-name {
      width: calc(100%/7.7);
    }

    .react-datepicker__day {
      background: white;
      font-weight: 500;
      border-radius: 3px;
      height: 44px;

      &.react-datepicker__day--selected, 
      &.react-datepicker__day--keyboard-selected {
        background: $purple;

        .date-cal .event-flag {
          background: white;
        }
      }
    }

    .react-datepicker__navigation--previous {
      left: inherit;
      right: 30px;
    }

    .react-datepicker__current-month {
      text-align: left;
      font-size: 24px;
      margin-bottom: 8px;
      margin-left: 10px;
    }

    .react-datepicker__year-read-view--down-arrow, 
    .react-datepicker__month-read-view--down-arrow, 
    .react-datepicker__month-year-read-view--down-arrow, 
    .react-datepicker__navigation-icon::before {
      border-color: black;
    }
  }

  .date-cal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;

    .event-flag {
      width: 8px;
      height: 8px;
      background: $pink;
      border-radius: 10px;
      position: absolute;
      top: 7px;
      left: calc(50% - 3px);
    }
  }
}