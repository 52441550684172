@import '../../../assets/styles/color.scss';

.top.search-bar {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .label {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .search-bar {
    width: 100%;
    background: white;
    border: 1px solid $light-grey;
    border-radius: 16px;
    margin: 0 auto 0;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    position: relative;

    .icon {
      margin-left: 8px;
      position: absolute;
      right: 16px;
      top: 10px;
    }

    input {
      width: 100%;
      border: 0;
      border-radius: 16px;
      padding: 8px 16px;
      box-sizing: border-box;
      height: 40px;
      font-size: 14px;

      &::placeholder {
        font-size: 14px;
      }
    }
  }
}