@import '../../../../assets/styles/color.scss';

.slide-dot-wrap {
  display: flex;

  .dot {
    width: 13px;
    height: 13px;
    margin-left: 9px;
    background: $light-grey;
    border-radius: 100%;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      background: $purple;
    }
  }
}