@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/color.scss';

.input-time-container {
  position: relative;

  &.err {
    .label {
      color: red;
    }

    .input-wrap input {
      border-color: red;
    }

    .input-group {
      .prependText {
        border-color: red;
      }
    }
  }

  &.hanging-label {
    .label {
      margin-bottom: 8px;
      font-weight: 500;
    }
  }

  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.text {
    .label {
      &:not(.active) {
        margin-left: 10px;
      }

      &.active {
        top: -10px;
      }
    }
  }

  .input-wrap {
    width: 100%;
    margin-bottom: 16px;
    position: relative;

    input {
      // @extend %input-styling;
      background: white;
      border-radius: 2px;
      border: 0;

      &:read-only {
        pointer-events: none;
      }

      /* placeholder */
      &::placeholder {
        color: white;
      }
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }

    .input-group {
      display: flex;
      align-items: center;

      input {
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 14px;
      }

      .divider {
        margin: 0 4px;
      }
    }

    .icon.icon-input-left {
      margin-right: 16px;
    }

    .icon.icon-input-right {
      position: absolute;
      right: 8px;
    }

    .detail-input {
      font-size: 12px;
      line-height: 14px;
      color: $dark-grey;
    }
  }
  
  .err-message {
    font-size: 12px;
    color: red;
    margin-top: 4px;
  }

  .detail-input {
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
  }
}